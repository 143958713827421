import http from "../axios-instance";

class TicketService {
   async openTicket(data, onUploadProgress) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
         onUploadProgress,
      };

      return http
         .post("/ticket", data, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateTicket(ticket) {
      return http
         .put(`/ticket/${ticket._id}`, ticket)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getTicket(ticket_id) {
      return http
         .get(`/ticket/${ticket_id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getTickets(params) {
      return http
         .get("/ticket", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getOpenTicketsCount() {
      return http
         .get("/open_tickets_count")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getUserOpenTicketsCount() {
      return http
         .get("/user_open_tickets_count")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getUserTickets(params) {
      return http
         .get("/ticket_user", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteTicket(id) {
      return http
         .delete(`/ticket/${id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadFile(ticket) {
      return http
         .get(`/ticket/${ticket._id}/download_file`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, ticket.file);
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   download(data, fileName) {
      let blob = new Blob([data], {
         type: "application/octet-stream",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName.split(/__fn__(.+)/)[1];
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
   }
}

export default new TicketService();
