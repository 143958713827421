import http from "../axios-instance";

class CouponService {

   get_by_code(coupon_code) {
      return http.get("/shop/coupon/get_by_code/" + coupon_code)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

  validate(coupon_code) {
    return http.get("/shop/coupon/validate/" + coupon_code)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

   get_by_id(id) {
      return http.get("/shop/coupon/get_by_id/" + id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   get_all({ name, category_id, status, sort_by, sort_order, page, size }) {
      return http.get(`shop/coupon/get_all?name=${name}&category_id=${category_id}&status=${status}&sort_by=${sort_by}&sort_order=${sort_order}&page=${page}&size=${size}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   add({ name, code, flat_amount, percentage, min_order_amount, min_order_product_count, start_date, expiry_date, category_id, product_id, status }) {
      return http.post("/shop/coupon/add", {
        name, code, flat_amount,
        percentage, min_order_amount,
        min_order_product_count,
        start_date: Date.parse(start_date),
        expiry_date: Date.parse(expiry_date),
        category_id,
        product_id, status
      })
        .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update_status({ id, status }) {
      return http.post("/shop/coupon/update_status", { id, status })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   update({ id, name, code, flat_amount, percentage, min_order_amount, min_order_product_count, start_date, expiry_date, category_id, product_id, status }) {
      return http.post("/shop/coupon/update", {
        id, name, code, flat_amount,
        percentage, min_order_amount,
        min_order_product_count,
        start_date: Date.parse(start_date),
        expiry_date: Date.parse(expiry_date),
        category_id, product_id, status
      })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   delete({ id }) {
      return http.post("/shop/coupon/delete", { id })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

}

export default new CouponService();
